import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledLink } from '../../../utils/ui';
import { getOriginalImageSrc } from '../../../utils/formatter';

const ProductTypeItemWrapper = styled(StyledLink)`
  img {
    aspect-ratio: 1/1;
  }
`;

const ProductTypeItem = ({ productType }) => {
  const { name, url, producttype_thumbnail } = productType;
  return (
    <li>
      <ProductTypeItemWrapper to={`/${url}`}>
        <img src={getOriginalImageSrc(producttype_thumbnail)} alt={name} />
        <p className="text_center">{name}</p>
      </ProductTypeItemWrapper>
    </li>
  );
};

ProductTypeItem.propTypes = {};

export default ProductTypeItem;
