import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StyledLink } from '../../../utils/ui';
import { getOriginalImageSrc, getFinalProductPrice } from '../../../utils/formatter';
import { MAX_WIDTH } from '../../../utils/constants';

const ProductItemWrapper = styled(StyledLink)`
  border: 1px solid #a88c69;
  padding: 26px;
  display: flex;
  flex-direction: column;
  .productitem-name {
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #292929;
    margin: 10px 0 0 0;
  }
  .productitem-price {
    margin-top: 9px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 0 0;
  }
  .productitem-image {
    width: 165px;
    height: 223px;
    box-sizing: border-box;
    display: flex;
    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0px auto;
    }
    margin-bottom: 16px;
  }
  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    align-items: center;
    justify-content: center;
  }
`;

const ProductItem = ({ product }) => {
  const { name, slug, product_thumbnail } = product;
  return (
    <li>
      <ProductItemWrapper to={`/product/${slug}`}>
        <div className="productitem-image">
          <img src={getOriginalImageSrc(product_thumbnail)} alt={name} />
        </div>
        <h4 className="productitem-name">{name}</h4>
        <h6 className="productitem-price color_light_brown">{getFinalProductPrice(product)}</h6>
      </ProductItemWrapper>
    </li>
  );
};

ProductItem.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    product_thumbnail: PropTypes.object,
  }),
};

export default ProductItem;
