import React,{useState} from 'react';
import styled from 'styled-components';
import { flattenStrapiData } from '../utils/formatter';
import SiteLayout from '../components/SiteLayout';
import RelatedProducts from '../components/page/ProductPage/RelatedProducts';
import RelatedProductTypes from '../components/page/ProductPage/RelatedProductTypes';
import BannerTextWithBreadcrum from '../components/common/BannerTextWithBreadcrum';
import Products from '../components/page/ProductTypePage/Products';
import { MAX_WIDTH } from '../utils/constants';
import { useMediaQuery } from 'react-responsive';

const ProductTypeWrapper = styled.div`
  margin-top: 88px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .products_ruou_ca_cop_container {
    margin: 74px auto 100px auto;
  }
  .ruou_ca_cop_read_more {
    margin-bottom: 50px;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    .ruou_ca_cop_read_more {
      width:50%;
    }
  }
`;

const ProductType = ({
  data: { strapiProductType: productTypeDetail, allStrapiProductType: productTypes, allStrapiProduct: relatedProducts },
}) => {
  const { id, url } = productTypeDetail;
  const LAYOUT_KEY = 'CA_COP';

  const breadcrumTree = [
    {
      id: 0,
      name: 'Trang chủ',
      slug: '/',
    },
    {
      id: 1,
      name: 'Sản phẩm',
      slug: '/product',
    },
    {
      id: 2,
      name: 'Rượu',
      slug: '/ruou-ca-cop',
    },
  ];
  const isMobile = useMediaQuery({ query: `(max-width: ${MAX_WIDTH})` });

  const pageSize = isMobile ? 3 : 9

  const [viewCount,setViewCount] = useState(pageSize)  

  return (
    <SiteLayout activeKey={LAYOUT_KEY}>
      <ProductTypeWrapper className="background_color_theme">
        <BannerTextWithBreadcrum title="lão tửu hạ thổ" breadcrumTree={breadcrumTree} />
        <div className="products_ruou_ca_cop_container">
          <Products relatedProducts={flattenStrapiData(relatedProducts)?.slice(0,viewCount)} productTypeSlug={url} />
        </div>
        {(viewCount < relatedProducts.edges.length) ? 
        <a className="button read_more ruou_ca_cop_read_more" onClick={()=> setViewCount(viewCount+pageSize)}>
          Xem thêm
        </a> : ''}
        <RelatedProductTypes productTypes={flattenStrapiData(productTypes)} />
      </ProductTypeWrapper>
    </SiteLayout>
  );
};

ProductType.propTypes = {};

export default ProductType;

export const query = graphql`
  query {
    strapiProductType(url: { eq: "ruou-ca-cop" }) {
      id
      name
      url
      description
      producttype_images {
        url
      }
      producttype_thumbnail {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      banner {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      banner_mobile {
        localFile {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
    }
    allStrapiProduct(filter: { product_type: { url: { eq: "ruou-ca-cop" } } }) {
      edges {
        node {
          id
          name
          subname
          unit_price
          discounted_price
          slug
          product_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
    allStrapiProductType(filter: { url: { ne: "ruou-ca-cop" } }) {
      edges {
        node {
          id
          name
          description
          url
          producttype_thumbnail {
            localFile {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
