import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import ProductItem from './ProductItem';
import { Slider } from '../../common';
import { MAX_WIDTH } from '../../../utils/constants';

const RelatedProductsWrapper = styled.div`
  width: 100%;
  .related-products-title {
    margin: 87px 0px 46px 0px;
  }
  .all-products-button {
    margin: 54px auto;
  }
  .slider-arrow {
    cursor: pointer;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    .related-products-title {
      margin: 87px 0px 46px 26px;
    }
  }
`;

const RelatedProducts = ({ relatedProducts, productTypeSlug }) => {
  return (
    <RelatedProductsWrapper>
      <h5 className="related-products-title color_light_brown">Các sản phẩm khác</h5>
      <Slider>
        {relatedProducts?.map((product) => {
          return <ProductItem key={product.id} product={product} />;
        })}
      </Slider>
      <Link className="button read_more white all-products-button" to={`/${productTypeSlug}`}>
        TẤT CẢ SẢN PHẨM
      </Link>
    </RelatedProductsWrapper>
  );
};

RelatedProducts.propTypes = {
  relatedProducts: PropTypes.array,
  productTypeSlug: PropTypes.string,
};

export default RelatedProducts;
