import React from 'react';
import styled from 'styled-components';
import Breadcrum from './Breadcrum';
import { MAX_WIDTH } from '../../utils/constants';

const BannerTextWithBreadcrumWrapper = styled.div`
  width: 100%;
  height: 207px;
  background-color: #e1dfd9;
  div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto auto;
  }
  @media screen and (max-width: ${MAX_WIDTH}) {
    div {
      width: calc(100% - 20px);
      flex-direction: column;
      justify-content: center;
      align-items: start;
      margin: 0 10px;
    }
  }
`;

const BannerTextWithBreadcrum = ({ title, breadcrumTree }) => {
  return (
    <BannerTextWithBreadcrumWrapper>
      <div className="max_width">
        <h2 className="color_light_grey">{title}</h2>
        {breadcrumTree?.length > 0 ? <Breadcrum breadcrumTree={breadcrumTree} /> : null}
      </div>
    </BannerTextWithBreadcrumWrapper>
  );
};

export default BannerTextWithBreadcrum;
