import React from 'react';
import styled from 'styled-components';
import { StyledLink } from '../../../utils/ui';
import { getFinalProductPrice, getOriginalImageSrc } from '../../../utils/formatter';
import { MAX_WIDTH } from '../../../utils/constants';



const ProductItemWrapper = styled(StyledLink)`
  width: 291px;
  height: 432px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  overflow: hidden;
  position: relative;
  .product_item_content_text {
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
  }
  .product_item_button {
    transition: opacity 0.25s ease-in-out;
    position: absolute;
    bottom: 34px;
    right: 16px;
    opacity: 0;
  }
  img {
    height: 290px;
    width: auto;
  }
  p {
    margin: 8px 0px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.02em;
  }
  .bold {
    font-weight: normal;
  }
  .product_item_title {
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #000000;
    margin: 50px 0 0 0;
  }
  .product_item_price {
    margin: 10px 0;
  }
  :hover {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    .product_item_content_text {
      opacity: 0;
    }
    .product_item_button {
      opacity: 1;
    }
  }
`;

const ProductItem = ({ product }) => {
  const { name, subname, slug, product_thumbnail } = product;

  return (
    <ProductItemWrapper to={`/product/${slug}`}>
      <div className="product_item_content">
        <div className="content_center_row">
          <img src={getOriginalImageSrc(product_thumbnail)} alt={name} />
        </div>
        <div className="product_item_content_text">
          <h3 className="product_item_title text_center no_text_decoration">{name}</h3>
          <h5 className="product_item_price text_center no_text_decoration color_light_brown">{getFinalProductPrice(product)}</h5>
        </div>
        <StyledLink className="product_item_button button read_more color_white">Xem sản phẩm</StyledLink>
      </div>
    </ProductItemWrapper>
  );
};

const ProductsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  @media screen and (max-width: ${MAX_WIDTH}) {
    grid-template-columns: 1fr;
  }
`;

const Products = ({ relatedProducts, productTypeSlug }) => {
  return (
    <ProductsWrapper>
      {relatedProducts?.map((product) => {
        return <ProductItem key={product.id} product={product} />;
      })}
    </ProductsWrapper>
  );
};

export default Products;
