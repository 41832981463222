import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ProductTypeItem from './ProductTypeItem';
import { Slider } from '../../common';
import { MAX_WIDTH } from '../../../utils/constants';
import { StyledLink } from '../../../utils/ui';

const RelatedProductTypesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  .inner-content {
    max-width: ${MAX_WIDTH};
    padding-top: 98px;
  }
  .relatedProductTypes-title {
    font-family: Cormorant Infant;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 45px;
  }
  .all-products-button {
    margin: 54px auto;
  }
  ul {
    justify-content: center;
  }
`;

const RelatedProductTypes = ({ productTypes }) => {
  return (
    <RelatedProductTypesWrapper className="color_gradient_brown">
      <div className="inner-content">
        <h1 className="relatedProductTypes-title color_white">Các dòng sản phẩm khác</h1>
        <Slider>
          {productTypes?.map((productType) => {
            return <ProductTypeItem key={productType.id} productType={productType} />;
          })}
        </Slider>
        <StyledLink className="button no_fill dark all-products-button" to={`/product`}>
          TẤT CẢ DÒNG SẢN PHẨM
        </StyledLink>
      </div>
    </RelatedProductTypesWrapper>
  );
};

RelatedProductTypes.propTypes = {
  productTypes: PropTypes.array,
};

export default RelatedProductTypes;
